import { TAppTheme } from 'common/casl/config/theme-ability'
import { ReactNode } from 'react'
import dynamic from 'next/dynamic'

const MomentsLogin = dynamic(() => import('modules/login/moments-login'), {
  ssr: false,
})

const InfocareLogin = dynamic(() => import('modules/login/infocare-login'), {
  ssr: false,
})

const APP_THEME = (process.env.NEXT_PUBLIC_APP_THEME ?? 'moments') as TAppTheme

const AuthLogin = () => {
  const component: Record<TAppTheme, ReactNode> = {
    moments: <MomentsLogin />,
    infocare: <InfocareLogin />,
  }

  return <>{component[APP_THEME]}</>
}

export default AuthLogin
